@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
      /*  padding-top: 50px;*/
       
    }
    /*.alert-enter {
        opacity: 0;
        transform: scale(0.9);
    }
    .alert-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .alert-exit {
        opacity: 1;
    }
    .alert-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }*/

    .alert-enter {
        opacity: 0;
        transform: scale(0.9);
    }

    .alert-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
    }

    .alert-exit {
        opacity: 1;
    }

    .alert-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }

    
}

/* ------------------ Login Section ---------------------*/

.ShowPwdIcon {
    content: url(images/ShowPwd.png);
    background: url(images/ShowPwd.png);
    width: 20px;
    height: 20px;
    background-size: contain;
}

.HidePwdIcon {
    content: url(images/HidePwd.png);
    background: url(images/HidePwd.png);
    width: 20px;
    height: 20px;
    background-size: contain;
}



/* ------------------ End of Login Section ---------------------*/
/* -----------------Message bo style------------------------------------------*/

.messageDialog {
    margin-top: 10%;
    margin-left: 8%;
    width: 100%;
    height: 280px;
    background-color: transparent;
}

.errorMessage {
    background-color: grey;
    color: white;
}

.messageTitle {
    background-color: grey;
    font-family: Gibson;
    text-align: center;
    color: white;
}


.rowColumn {
    margin-bottom: 40px!important;
}

.messageTextStyle {
    text-align: center;
    padding-top: 10px;
    font-size: 13px;
    font-weight: bold
}

.successButtonStyle {
    width: 100px;
    background-color: limegreen;
    border-color: limegreen;
}

.errorButtonStyle {
    width: 100px;
    background-color: red !important;
    /*font-family: Verdana;*/
    border-color: red !important;
    /*color: white !important;*/
}

.displayButtonStyle {
    display: none;
}
/* -----------------Message style------------------------------------------*/
/* ------------------------Email style---------------------------------- */
.cancelButtonStyle {
    width: 100px;
    background-color: #fff !important;
    border-color: #fff !important;
}
/* ---------------------End of Email style------------------------------ */
.Resizer {
    box-sizing: border-box;
    background: #000;
    opacity: 0.5;
    z-index: 1;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}



/*-------------------Action------------------------*/

.spanAction {
    cursor: pointer !important;
    color: blue !important;
    text-decoration: underline !important;
}
    .spanAction:hover {
        text-decoration: none !important;
        cursor: pointer !important;
    }

/*------------------Action----------------------------------*/

/*-------------------Chart------------------------*/

.FunnelChartTitle {
    text-align: center;
    font-size: 16px;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    color: #666;
    font-weight: bold;
    padding: 18px 10px 25px 10px;
    line-height: 1.2px
}

.FunnelChartLegend {
    padding-bottom: 5px;
    font-size: 13px;
    font-style: normal;
    color: #989FA6;
    font-family: 'Gibson','Arial','Helvetica',sans-serif;
}
/*-------------------Chart------------------------*/

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}