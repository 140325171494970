.dropdiv {
    width: 100%;
    /* width: 176px;*/
    min-height: 72px;
    max-height:100%;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.36);
    box-sizing: border-box;
}

    .dropdiv:focus {
        outline: 1px solid blue !important;
        background-color: red !important;
    }

.dragdiv {
    width: 100%;
    height: 72px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.36);
    box-sizing: border-box;
}

.ddcontainer {
    width: 100% !important;
}

.ddcontainersource {
    width: 100% !important;
}

.draggablediv {
    width: 176px;
    height: 25px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.36);
    box-sizing: border-box;
    padding: 0px !important;
}


.highlighted .dropdiv {
    background-color: lightgrey !important;
}
   


.editIcon {
    font-size: 14px !important;
    cursor: pointer;
}
.pointerIcon {
    cursor: pointer;
}


.plusIcon {
    font-size: 10px !important;
}

.hide {
    display: none;
}

.rowDiv:hover .editDiv {
    display: inline !important;
}




.editDiv {
    border-style: none !important;
    width: 20px;
}

.editSubDiv {
    border-style: none !important;
    width: 20px;
    text-align: right
}

/*.rowDiv:hover .dropdiv {
    background-color: #F4FAFF;
}*/

.rowDiv:hover {
    background-color: #F4FAFF;
}
.rowDivBg {
    background-color: #F4FAFF;
}


/*.rowDiv:hover .subRowDiv .dropdiv {
    background-color: #F5FFF6 ;
}*/

.subRowColumn:hover {
    background-color: #F5FFF6;
}

.subRowColumnBg{
    background-color: #F5FFF6;
}

.subRowDiv:hover .dropdiv {
    background-color: #F5FFF6 ;
}

.subRowDiv:hover .editSubDiv {
    display: inline !important;
    background-color: #F5FFF6 ;
}

.subRowDiv {
    /* border: 1px solid rgba(0, 0, 0, 0.36);*/
}

.rowDiv {
    /* border: 1px solid rgba(0, 0, 0, 0.36);*/
}

.spanControl {
     margin-left: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /*display: flex;*/
    align-items: center;
    text-align: center;
    letter-spacing: 0.0025em;
    color: rgba(0, 0, 0, 0.87);
}
.spanControlFormUi {
    /*margin-left: 10px !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 14px;*/
    /*display: flex;*/
    /*align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.54);*/
    word-break: break-word;
}
.formControl {
    color: rgba(0, 0, 0, 0.54);
}

.containerControl {
    margin: 5px !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
    -ms-border-radius: 4px;
    border-radius: 4px;
}

.MuiSvgIcon-root .deleteButton {
    font-size: 12px !important;
}

.spanButton {
    font-size: 12px;
    margin-left: 5px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1.25px;
    text-align: center;
    cursor:pointer;
}

.tabContainer {
    overflow: scroll;
}

.MuiTabs-fixed {
    overflow-x: scroll !important;
}

.editArrowStyle1 {
    /* color: #2196F3;
    fill: #2196F3;
    stroke: #2196F3;*/
    /* height: 13.16px;
    width: 11.67px;*/
    padding: 0px;
    font-size: 14px !important;
    cursor: pointer;
    visibility: hidden;
}

.editArrowStyle2 {
    height: 14.16px;
    width: 12.67px;
    padding: 0px;
    font-size: 12px !important;
    cursor: pointer;
    visibility: hidden
}

.addIcon {
    position: relative;
    top: 35%;
    left: 50%;
    font-size: 18px !important;
    cursor: pointer
    /* margin-top:50%;
    margin-bottom:50%*/
}

.subRowAddIcon {
    position: relative;
    margin-top: 27px;
    /* left: 50%;*/
    font-size: 18px !important;
    fill: #43A047;
    stroke: #43A047;
    cursor: pointer
}

.subRowIcon {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.editStyle1 {
    padding: 0px;
    font-size: 16px !important;
    cursor: pointer;
    color: #474f54
}

.editStyle2 {
    padding: 0px;
    font-size: 16px !important;
    cursor: pointer;
    color: #474f54
}



.editDiv:hover .editArrowStyle1 {
    visibility: visible
}

.editSubDiv:hover .editArrowStyle2 {
    visibility: visible;
    fill: #43A047;
    stroke: #43A047;
}

.editSubDiv:hover .editStyle2 {
    color: #43A047;
}

.editDiv:hover .editStyle1 {
    color: #2196F3;
}

.rowDiv:hover .addIcon {
    color: #2196F3;
    fill: #2196F3;
    stroke: #2196F3;
}


.subRowColumn {
    border-color: #43A047;
    text-align: center;
    vertical-align: middle
}

.subRowDeleteIcon {
    top: 0;
    font-size: 16px !important;
    cursor: pointer;
}

.subRowDeleteItem {
    width: 20px;
    text-align: center;
}

.highlighted .dropdiv .subRowColumn {
    background-color: lightgrey !important;
}
.highlighted .subRowColumn{
    background-color: lightgrey !important;
}


.subRowGridColumn {
    border-color: #FFA117;
    text-align: center;
    vertical-align: middle;
    
}
.subRowGridAddIcon {
    position: relative;
    margin-top: 27px;
    /* left: 50%;*/
    font-size: 18px !important;
    fill: #FFA117;
    stroke: #FFA117;
    cursor:pointer
}
.subRowGridColumn:hover {
    background-color: #FFF2DE ;
}

/*.rowDiv:hover .subRowGridColumn .dropdiv {
    background-color: #FFF2DE;
}*/

.subRowGridColumn:hover {
    background-color: #FFF2DE;
}
.subRowGridColumnBg {
    background-color: #FFF2DE;
}




