
body .p-spinner .p-spinner-input {
    width: 100%
}

body .p-calendar.p-calendar-w-btn .p-inputtext {
    width: 89% !important;
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
}

.p-calendar .p-calendar-button {
    height: auto !important;
    border-color: transparent !important;
    color: #555 !important;
    background-color: transparent !important;
    border: none;
}


body .p-inputtext {
    height: 41px !important;
    padding: 10px 10px 7px !important;
    font-family: 'Gibson', 'Arial', 'Helvetica', sans-serif !important;
    font-size: 19px !important;
    line-height: 1.2 !important;
    text-indent: 0 !important;
    text-align: left;
    border: 1px solid rgba(151, 151, 151, .27) !important;
    background-image: none !important;
    background-color: rgba(152, 159, 166, .06) !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .12) inset !important;
}

.btn {
    display: inline-block !important;
    vertical-align: middle;
    text-align: center !important;
    cursor: pointer !important;
    border: 1px solid rgba(0, 0, 0, .2) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .4) !important;
    font-family: 'Gibson', 'Arial', 'Helvetica', sans-serif !important;
    font-size: 14px !important;
    line-height: 1 !important;
    padding: 6px 16px 6px 6px !important;
    border-radius: 15px !important;
    transition: color .15s, border-color .15s, background .15s !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}


#frmBody div[class="k-header"] {
    padding: 20px 0 14px 0 !important;
    margin: 10px 0 0 0 !important;
    width: auto !important;
    font-family: 'LucidaSTD', 'Times', serif !important;
    font-size: 20px !important;
    font-weight: normal !important;
    font-style: normal !important;
    text-align: left !important;
    vertical-align: middle !important;
    line-height: 1.7 !important;
    color: #000 !important;
    background: none !important;
    border-top: 2px solid #dfe2e5 !important;
}

.form-row .btn .p-button-text {
    display: none !important;
    vertical-align: middle
}

.buttonPadding {
    padding: 0 !important
}

.p-spinner .p-spinner-button {
    color: black !important;
    background-color: transparent !important;
    border: none !important;
    background: transparent !important;
}


.signClear {
    top: 50%;
    margin: -8px 0 0;
    position: absolute;
}

#frmclearSig {
    position: absolute !important;
    top: 40px !important;
    right: 10px !important;
}

.fieldPadding {
    padding: 0px !important;
}


.form-body .lookupIcon {
    top: 22px !important;
    right: 13px !important;
}

.form-body .js-signature-wrapper {
    height: 160px !important;
}

.js-signature {
    height: auto !important;
}

    .js-signature .signature-control {
        width: 100% !important;
        height: 160px !important;
    }


.signature-control-image img, .signature-control img {
    height: auto !important;
    width: auto !important;
    max-height: 140px !important;
}

.signature-control {
    background: rgba(152, 159, 166, .06);
    border: 1px solid rgba(151, 151, 151, .27);
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .12) inset;
    width: 100%;
    padding: 10px 10px 7px;
    display: block;
    transition: border-color .2s, background .2s, box-shadow .2s;
}

.js-siganture-wrapper {
    height: 160px !important;
}

.signature-control-image {
    background: #fff;
    border: 1px solid rgba(151, 151, 151, .27);
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .12) inset;
    width: 100%;
    height: 160px !important;
    padding: 10px;
    display: block;
    transition: border-color .2s, background .2s, box-shadow .2s;
    text-align: center;
}

#frmclearSig {
    background-image: none;
    width: 45px;
    height: 20px;
    padding: 3px 8px 6px 6px !important;
}

#mobclearSig {
    background-image: none;
    width: 35px;
    height: 12px;
    padding: 3px 8px 3px 8px !important
}

#btnsignCancel {
    background-image: none;
    width: 35px;
    height: 12px;
    padding: 4px 13px 4px 8px !important;
}

#mobsigwinSpan {
    background-color: #fff !important;
}

#mobsignature {
    background-color: #5F6871;
}

.mobsignaturetitle {
    background-color: #5F6871 !important;
    padding-top: 10px !important;
}

#mobsigForm {
    padding: 20px 0px 0px 0px !important;
}

.mobsigactionbtn {
    opacity: .5;
}





body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
    cursor: pointer !important;
    background-color: #eaeaea !important;
    color: #333333 !important;
}



.p-datatable-highlight {
    cursor: pointer !important;
    background-color: #eaeaea !important;
    color: red !important;
}


.numeric-p-button .p-button {
    color: black !important;
    border: none !important;
    background: rgba(152, 159, 166, .06) !important;
}

.numericInput .p-inputnumber-input {
    border-right: none !important;
    border-right-color: rgba(152, 159, 166, .06) !important;
    border-right-style: none !important;
}

.customButton {
    padding: 4.5px !important;
    border: 1px solid rgba(151, 151, 151, .27) !important;
    background-image: none !important;
    background-color: rgba(152, 159, 166, .06) !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .12) inset !important;
    color: #555555 !important;
}

.editorToolbox .ql-toolbar.ql-snow {
    padding: 0px !important;
}


