.e-pager .e-pagercontainer {
    padding-left : 27.4%;
}
.e-input-group {
    border-color: black !important;
}
.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search{
    opacity:100 !important
}
.e-row {
   /* height: 43px !important;*/
}
