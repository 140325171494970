
.img45Style {
    border: none !important;
    width: 45px !important;
    height: 45px !important;
}

.img65Style {
    border: none !important;
    width: 65px !important;
    height: 65px !important;
}

.img250Style {
    border: none !important;
    width: 250px !important;
    height: 250px !important;
}

.imageCursor {
    cursor: pointer;
}

#preview, #lookupPreview {
    position: absolute;
    border: 1px solid #dbdbe4;
    display: none;
    color: #fff;
    z-index: 100000;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.05);
}


#lightBox {
    position: fixed; /* keeps the lightbox window in the current viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(153, 157, 163,.6);
    /*background: url(overlay.png) repeat;*/
    text-align: center;
    z-index: 100000 !important;
}



    #lightBox button {
        text-align: right;
        /*margin-right:1%;*/
        font-size: 12px;
    }

    #lightBox img {
        padding-top: 5% !important;
        padding-bottom: 10px !important;
        /*box-shadow: 0 0 25px #111;
        -webkit-box-shadow: 0 0 25px #111;
        -moz-box-shadow: 0 0 25px #111;*/
        max-width: 1024px;
        max-height: 960px !important;
    }

.close {
    padding-top: 3% !important;
    padding-right: 28% !important;
    font-size: 50px !important;
    position: absolute !important;
    right: 0;
    top: 0;
    font-weight: bold;
    font-size: 1.5em;
    text-decoration: none;
    color: #000;
}



#mobileLightBox {
    position: fixed; /* keeps the lightbox window in the current viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(153, 157, 163,.6);
    /*background: url(overlay.png) repeat;*/
    text-align: center;
    z-index: 100000 !important;
    -webkit-transform: translate3d(0,0,1px) !important;
    overflow-y: scroll
}

    #mobileLightBox img {
        padding-top: 5% !important;
        padding-bottom: 10px !important;
        width: 80%;
        /* max-width: 250px;*/
        
    }

    #mobileLightBox button {
        text-align: right;
        /*margin-right:1%;*/
        font-size: 12px;
    }

.mobileSpanLightBox {
    display: block;
    position: absolute;
    top: 4px;
    right: 5px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #F9F9F9;
    cursor: pointer;
    box-shadow: -2px 2px 6px 0px rgba(0,0,0,0.15);
    float: right;
}

    .mobileSpanLightBox:before, .mobileSpanLightBox:after {
        position: absolute;
        top: 8px;
        left: 21px;
        content: ' ';
        height: 26px;
        width: 1px;
        background-color: #333;
    }

    .mobileSpanLightBox:before {
        transform: rotate(45deg);
        cursor: pointer;
    }


    .mobileSpanLightBox:after {
        transform: rotate(-45deg);
        cursor: pointer;
    }
    
    


.spanLightBox {
    display: block;
    position: absolute;
    top: 4px;
    right: -22px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #F9F9F9;
    cursor: pointer;
    box-shadow: -2px 2px 6px 0px rgba(0,0,0,0.15);
}

    .spanLightBox:before, .spanLightBox:after {
        position: absolute;
        top: 8px;
        left: 21px;
        content: ' ';
        height: 26px;
        width: 1px;
        background-color: #333;
    }

    .spanLightBox:before {
        transform: rotate(45deg);
    }


    .spanLightBox:after {
        transform: rotate(-45deg);
    }


#lightboxlookup {
    position: fixed; /* keeps the lightbox window in the current viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(153, 157, 163,.6);
    /*background: url(overlay.png) repeat;*/
    text-align: center;
    z-index: 100000
}



    #lightboxlookup button {
        text-align: right;
        font-size: 12px;
    }

    #lightboxlookup img {
        padding-top: 5% !important;
        padding-bottom: 10px !important;
        max-width: 1024px;
        max-height: 960px !important;
    }
