#importConnection.e-grid {
    border: none;
}

#importConnection .e-gridheader {
        border: none;
    }

#importConnection .e-rowcell {
    border: none;
    padding: 0px;
}
#importConnection .e-headercell {
    
    padding: 0px;
}

.e-grid .e-headercelldiv {
    color: black !important;
}
.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
    padding: 0 0 0 0.6em !important;
}