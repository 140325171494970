:root {
    --theme-color: #6EADEA;
} 

.e-grid .e-rowcell {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.54) !important;
}


.e-grid .e-headercelldiv {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.54);
}
.e-grid .e-groupdroparea {
    background-color: rgba(0, 0, 0, 0.1);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    /* Material UI/button label · white · 100% */

    color: rgba(0, 0, 0, 0.54);
}
    .e-grid .e-groupdroparea.e-grouped {
        background-color: rgba(0, 0, 0, 0.1);
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        /* identical to box height */

        display: flex;
        align-items: center;
        /* Material UI/button label · white · 100% */

        color: #FFFFFF;
    }
.e-grid .e-groupheadercell, .e-grid .e-groupheadercell:hover {
    background-color: rgba(0, 0, 0, 0.36);
}
.e-grid .e-groupcaption {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.54);
}
.e-gridheader table {
    margin-bottom: 0px;
}
.e-colmenu{
    z-index:1201 !important;
}



@media only screen and (max-width: 1024px) {
    .e-groupdroparea {
        font-size: 14px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        min-height: 0px;
    }
    
    .e-detailrowexpand {
        padding-top: 10px!important;
    }
    .e-detailrowcollapse {
        padding-top: 10px !important;
    }
    .customcss {
        padding-left: 0px;
        padding-Top: 5px !important;
    }
    .e-templatecell {
        min-height: 30px !important;
    }
    /*to fix thead sort popup issue input mobile view datagrid*/
    .e-dlg-container {
        z-index: 1501!important;
    }
}

.e-bigger .e-pager .e-pagercontainer, .e-pager.e-bigger .e-pagercontainer {
    padding-left: 0px !important;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: var(--theme-color);
}
input.e-input::selection {
    background: var(--theme-color) !important;
}
.e-pager .e-currentitem {
    background: var(--theme-color) !important;
}
.e-bigger .e-grid td.e-active:last-child {
    border-bottom: 1px solid #ffffff !important;
}
.e-bigger .e-grid.e-row-responsive .e-gridcontent td::before {
    text-overflow: ellipsis;
    overflow: hidden;
}
.e-grid th.e-headercell[aria-sort='ascending'] .e-headertext, 
.e-grid th.e-headercell[aria-sort='descending'] .e-headertext, 
.e-grid th.e-headercell[aria-sort='ascending'] .e-sortfilterdiv, 
.e-grid th.e-headercell[aria-sort='descending'] .e-sortfilterdiv {
    color: #000;
    opacity: 1;
    margin-right: 14px;
}

.e-grid.e-wrap .e-gridheader .e-sortnumber {
    margin: 1px 18px 0 2px;
}
 
    .e-grid .e-leftalign .e-icon-descending::before, .e-grid-menu .e-icon-descending::before {
        /*margin-left: 0px;*/
    }
.e-grid .e-rightalign .e-icon-ascending::before, .e-grid-menu .e-icon-ascending::before {
   /* margin-left: -20px;*/
}

.e-grid .e-columnheader.e-wrap .e-fltr-icon.e-rightalign .e-sortfilterdiv, .e-grid.e-wrap .e-columnheader .e-fltr-icon.e-rightalign .e-sortfilterdiv {
    margin: -8px -13px 0px;
}




