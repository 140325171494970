
*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #f0f0f0;
}

/*article,
aside {
    display: block;
}*/

blockquote:last-child {
    margin-bottom: 0;
}

.chartjs-tooltip {
    opacity: 1;
    position: absolute;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(0, -120%);
    border-color: rgba(0, 0, 0, 0.12);
    border-radius: 19px;
    border-style: solid;
    border-width: 1px;
}

