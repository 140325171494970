:root {
    --theme-color: #6EADEA;
} 
.e-appointment-details {
    background: var(--theme-color);
}
.e-schedule {
    /* min-height: 330px;*/
    min-height: 150px;
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: var(--theme-color) !important;
}

.e-schedule .e-agenda-view .e-appointment {
    border-left: 3px solid var(--theme-color) !important;
}
.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-appointment, .e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-appointment-indicator {
    background: var(--theme-color) !important;
}
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: var(--theme-color) !important;
}
 .e-schedule .e-vertical-view .e-clone-time-indicator, .e-schedule .e-vertical-view .e-current-time
  {
        color: var(--theme-color) !important;
    }
    .e-schedule .e-vertical-view .e-current-timeline {
        border-top-color: var(--theme-color) !important; 
    }
.e-quick-popup-wrapper {
    z-index: 1300 !important;
}
.e-more-popup-wrapper {
     z-index: 1200 !important;
}
.e-quick-popup-wrapper .e-event-popup .e-popup-header {
    background-color: white !important;
    z-index: 10000 !important;
}
.e-schedule.e-device .e-schedule-toolbar .e-toolbar-pop {
    top:57px !important;
}
.e-datepicker .e-model-header {
    background-color: var(--theme-color) !important;
}
.e-more-popup-wrapper .e-appointment {
    background: var(--theme-color) !important;
    
}

.quick-info-header {
    background-color: white;
    padding: 8px 18px;
}

.quick-info-header-content {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px;
    color: #FFFFFF;
    background: var(--theme-color);
}

.quick-info-title {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.48px;
    height: 22px;
}

.duration-text {
    font-size: 11px;
    letter-spacing: 0.33px;
    height: 14px;
}

.content-area {
    margin: 0;
    padding: 10px;
    width: auto;
}

.event-content {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
}


.meeting-subject-wrap {
    font-size: 11px;
    color: #666;
    letter-spacing: 0.33px;
    padding: 5px;
}

.event-content div label {
    display: inline-block;
    min-width: 45px;
    color: #666;
}

.event-content div span {
    font-size: 11px;
    color: #151515;
    letter-spacing: 0.33px;
    line-height: 14px;
    padding-left: 8px;
}

.cell-footer .e-btn {
    background-color: #ffffff;
    border-color: #878787;
    color: #878787;
}

.cell-footer {
    padding-top: 10px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
    padding: 0 14px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
    display: block !important;
}

.e-quick-popup-wrapper .e-popup-footer button:first-child {
    margin-right: 5px;
}
.e-custom {
    float: left;
 
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text {
    color: var(--theme-color) !important;
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day {
    color: var(--theme-color) !important;
}
.e-schedule .e-month-view .e-current-date .e-date-header {
    background: var(--theme-color) !important;
}
.e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: var(--theme-color) !important;
}
.e-calendar .e-content td.e-today.e-selected span.e-day {
    background-color: var(--theme-color) !important;
    border: 1px solid var(--theme-color) !important;
}
.e-schedule .e-agenda-view .e-day-date-header.e-current-day {
    color: var(--theme-color) !important;
}
.e-btn.e-flat.e-primary:hover {
    color: var(--theme-color) !important;
    background-color: rgba(0, 0, 0, 0.0348);
    border-color: transparent;
}
.e-btn.e-flat.e-primary {
    color: var(--theme-color) !important;
    background-color: transparent;
}
.e-calendar .e-content.e-year td.e-selected:hover span.e-day {
    background-color: var(--theme-color) !important;
}
.e-calendar .e-content td.e-selected span.e-day {
    background-color: var(--theme-color) !important;
}
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
    color: var(--theme-color) !important;
}
.e-float-input .e-control-wrapper .e-no-float-label .e-input-group .customSchDatePicker .e-date-wrapper .e-valid-input .e-input-focus {
    background-color: var(--theme-color) !important;
    color: var(--theme-color) !important;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
    background: var(--theme-color) !important;
}
.e-float-line::before, .e-float-line::after {
    background: var(--theme-color) !important;
}
.e-calendar .e-content td.e-today:hover span.e-day {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
}
.e-calendar .e-content td.e-today span.e-day {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
}
.e-popup.e-popup-open {
   /* margin-left: 47px !important;
    margin-right: 47px !important;*/
}
/* added to disable the date picker input scheduler*/
/*.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
    display: none;
}*/

.e-quick-popup-wrapper.e-device {
    height: 250px !important;
    width: 250px !important;
    margin-left: 16% !important;
    MARGIN-TOP: 21% !important;
}
.e-bigger .e-more-popup-wrapper.e-device, .e-more-popup-wrapper.e-device {
    z-index: 5002;
    left: 13% !important;
    top: 0px;
    height: 100% !important;
    width: 80% !important;
}
.e-float-input.e-control-wrapper input::selection {
    background: var(--theme-color) !important;
}

@media screen and (max-width: 414px) {
    .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item #schdatepicker {
        width: 100px !important;
    }
    .e-btn-icon e-icon-today e-icons e-icon-left {
        display: none!important;
    }
/*
        .e-btn-icon e-icon-day e-icons e-icon-left e-tbar-btn-text {
            display: none !important;
        }*/
}
 

 


