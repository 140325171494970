.CropOuterContainer {
}
.cropImageDialog {
   /* margin-left: 8%;*/
    width: 100%;
    height: 72%;
    background-color: transparent;
    z-index: 30000!important;
}

.css-bdjl4r {
    border-radius: 50% !important;
}

.crop-controls .groupControl {
    display: flex;
    margin-top: 13px;
}

    .crop-controls .groupControl .leftControl {
        margin-left: auto;
    }

    .crop-controls .groupControl .cropbutton {
        margin-left: 22px;
    }

    .MuiDialog-paperWidthSm {
        width: 500px;
        height: 800px;
    }


    .form {
        height: auto !important;
    }

